import store from '../store'
import axios from 'axios';

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8';

axios.interceptors.response.use(response => {
    
    return (response) ? response : null;
}, (error) => {
    if (error.response && error.response.status === 401) {
        store.dispatch("logout");
        window.location.href = '/';
        return Promise.resolve();
    }
    //return Promise.reject(error);
});

axios.interceptors.request.use(function (config) {
    const token = store.state.user.authToken;
    const Usu_Id = store.state.user.Usu_Id;
    const Emp_Id = store.state.user.Emp_Id;
    

    if (token) {
        config.headers['usu-token'] = `${token}`;
        /* config.headers['Authorization'] = `${token}`; */
        config.headers['usu-id'] = `${Usu_Id}`;
        config.headers['emp-id'] = `${Emp_Id}`;
    }
    config.headers['Content-Type'] = 'application/json';
    return config;
}, function (err) {
    return Promise.reject(err);
});

export default axios;