import Vue from 'vue';
import Vuetify from 'vuetify/lib';


Vue.use(Vuetify);

export default new Vuetify({
  icons:{
    iconfont:"mdi"
  },
  theme: {
    themes: {
      light: {
        primary: '#0097A7', //cyan darken-2
        cyan: '#00BCD4', //cyan 
        cyan4: "#B2EBF2", // cyan claro
        secondary: '#ffffff', //blanco
        dark: '#4A4A4A', //negro
       
      },
      dark: {
        primary: '#0097A7', //cyan darken-2
        cyan: '#00BCD4', //cyan 
        cyan4: "#B2EBF2", // cyan claro
        secondary: '#4A4A4A', //negro
        light: '#ffffff', //blanco
      },
    }
  }
});
