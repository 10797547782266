import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store/index";
/* import VChart from 'v-chart'; */


Vue.use(VueRouter);
/* Vue.use(VChart); */



const routes = [
  
  {
    path: "/auth/:token/:usu_id",
    name: "Authenticate",
    props: true,
    component: () => import("../views/access/Authenticate.vue")
  },
  {
    path: "/notfound",
    name: "NotFound",
    component: () => import("../views/access/NotFound.vue")
  },
  {
    path: "/unauthorized",
    name: "Unauthorized",
    component: () => import("../views/access/Unauthorized.vue")
  },
  {
    path: "/",
    name: "home",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/Home.vue"),
    props: true,
  },
  {
    path: "/colab",
    name: "colaboradores",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/Colaboradores/colab.vue"),
    props: true,
  },
  {
    path: "/CRMColab",
    name: "CRMColab",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/CRMColab/CRMColab.vue"),
    props: true,
  },

  {
    path: '/HistorialCRM/:Con_Id',
    name: 'HistorialCRM',
    component: () => import("../views/CRMColab/HistorialCRM.vue"),
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/Contacto/:Con_Id',
    name: 'Contacto',
    component: () => import("../views/CRMColab/Contacto.vue"),
    meta: {
      requiresAuth: true
    }
  },

  {
    path: "/reporteLicencias",
    name: "reporteLicencias",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/Reporte/reporteLicencias.vue"),
    props: true,
  },
  {
    path: "/logLicencias",
    name: "logLicencias",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/Reporte/logLicencias.vue"),
    props: true,
  },
  {
    path: "/dashboard",
    name: "dashboard",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/Experiencia/Dashboard/dashboard.vue"),
    props: true,
  },
  {
    path: "/listadoENC",
    name: "listadoENC",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/Experiencia/listadoENC.vue"),
    props: true,
  },
  {
    path: "/ficha/:Usu_Id",
    name: "ficha",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/Experiencia/ficha/ficha.vue"),
    props: true,
  },
  {
    path: "/participacion",
    name: "participacion",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/Experiencia/participacion.vue"),
    props: true,
  },
  {
    path: "/detalle/:Usu_Id",
    name: "detalle",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/Detalle/detalle.vue"),
    props: true,
  },
  {
    path: "/datosGenerales",
    name: "detalle",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/Detalle/datosGenerales.vue"),
    props: true,
  },
  {
    path: "/formUsuario/:Usu_Id",
    name: "formUsuario",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/Nuevo/formUsuario.vue"),
    props: true,
  },

  /* documentos privados */
  {
    path: "/documentosPrivados",
    name: "documentosPrivados",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/DocumentosPrivados/documentosPrivados.vue"),
    props: true,
  },
  {
    path: "/documentosPrivadosDetail/:Dti_Id",
    name: "documentosPrivadosDetail",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/DocumentosPrivados/documentosPrivadosDetail.vue"),
    props: true,
  },

  
/* Campañas */
{
  path: "/campaign", 
  name: "campaign",
  meta: {
    requiresAuth: true,
  },
  component: () => import("../views/Campaigns/campaign.vue"),
  props: true,
},

{
  path: "/resultadosCampaign", 
  name: "resultadosCampaign",
  meta: {
    requiresAuth: true,
  },
  component: () => import("../views/Campaigns/ResultadosCampaign/resultadosCampaign.vue"),
  props: true,
},


/* Campañas YOMOB */
{
  path: "/campaignYomob", 
  name: "campaignYomob",
  meta: {
    requiresAuth: true,
  },
  component: () => import("../views/CampaignsYomob/campaignYomob.vue"),
  props: true,
},

{
  path: "/resultadosCampaignYomob", 
  name: "resultadosCampaignYomob",
  meta: {
    requiresAuth: true,
  },
  component: () => import("../views/CampaignsYomob/ResultadosCampaignYomob/resultadosCampaignYomob.vue"),
  props: true,
},

 /* clima */
  {
    path: "/dashboardClima", 
    name: "dashboardClima",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/Clima/DashboardClima/dashboardClima.vue"),
    props: true,
  },
  {
    path: "/participacionClima", //si la ruta no tiene /:Enc_Id lo hacemos con query params
    name: "participacionClima",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/Clima/participacionClima.vue"),
    props: true,
  },
  {
    path: "/resultados",
    name: "resultados",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/Clima/ResultadosClima/resultados.vue"),
    props: true,
  },
  {
    path: "/comentarios/:Enc_Id",
    name: "comentarios",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/Clima/DashboardClima/Comentarios/comentarios.vue"),
    props: true,
  },
  {
    path: "/detalles/:Enc_Id",
    name: "detalles",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/Clima/DashboardClima/Detalles/detalles.vue"),
    props: true,
  },

  /* clima YOMOB*/
  {
    path: "/dashboardClimaYomob",
    name: "dashboardClimaYomob",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/ClimaYomob/DashboardClimaYomob/dashboardClimaYomob.vue"),
    props: true,
  },
  {
    path: "/participacionClimaYomob", 
    name: "participacionClimaYomob",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/ClimaYomob/participacionClimaYomob.vue"),
    props: true,
  },
  {
    path: "/resultadosYomob",
    name: "resultadosYomob",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/ClimaYomob/ResultadosClimaYomob/resultadosYomob.vue"),
    props: true,
  },
  {
    path: "/comentariosYomob/:Enc_Id",
    name: "comentariosYomob",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/ClimaYomob/DashboardClimaYomob/ComentariosYomob/comentariosYomob.vue"),
    props: true,
  },
  {
    path: "/detallesYomob/:Enc_Id",
    name: "detallesYomob",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/ClimaYomob/DashboardClimaYomob/DetallesYomob/detallesYomob.vue"),
    props: true,
  },

/* encuestas clima */

{
  path: "/encuestasClima",
  name: "encuestasClima",
  meta: {
    requiresAuth: true,
  },
  component: () => import("../views/Clima/EncuestasClima/encuestasClima.vue"),
  props: true,
},

{
  path: "/crearEncuestaClima/:Enc_Id",
  name: "crearEncuestaClima",
  meta: {
    requiresAuth: true,
  },
  component: () => import("../views/Clima/EncuestasClima/crearEncuestaClima.vue"),
  props: true,
},

/* cuestionarios clima */
{
  path: "/cuestionariosClima",
  name: "cuestionariosClima",
  meta: {
    requiresAuth: true,
  },
  component: () => import("../views/Clima/CuestionariosClima/cuestionariosClima.vue"),
  props: true,
},

{
  path: "/crearCuestionarioClima/:Cst_Id",
  name: "crearCuestionarioClima",
  meta: {
    requiresAuth: true,
  },
  component: () => import("../views/Clima/CuestionariosClima/crearCuestionarioClima.vue"),
  props: true,
},

/* encuestas climaYOMOB */

{
  path: "/encuestasClimaYomob",
  name: "encuestasClimaYomob",
  meta: {
    requiresAuth: true,
  },
  component: () => import("../views/ClimaYomob/EncuestasClimaYomob/encuestasClimaYomob.vue"),
  props: true,
},

{
  path: "/crearEncuestaClimaYomob/:Enc_Id",
  name: "crearEncuestaClimaYomob",
  meta: {
    requiresAuth: true,
  },
  component: () => import("../views/ClimaYomob/EncuestasClimaYomob/crearEncuestaClimaYomob.vue"),
  props: true,
},

/* cuestionarios climaYOMOB */
{
  path: "/cuestionariosClimaYomob",
  name: "cuestionariosClimaYomob",
  meta: {
    requiresAuth: true,
  },
  component: () => import("../views/ClimaYomob/CuestionariosClimaYomob/cuestionariosClimaYomob.vue"),
  props: true,
},

{
  path: "/crearCuestionarioClimaYomob/:Cst_Id",
  name: "crearCuestionarioClimaYomob",
  meta: {
    requiresAuth: true,
  },
  component: () => import("../views/ClimaYomob/CuestionariosClimaYomob/crearCuestionarioClimaYomob.vue"),
  props: true,
},


/* asistencia */

{
  path: "/controlAsistencia",
  name: "controlAsistencia",
  meta: {
    requiresAuth: true,
  },
  component: () => import("../views/Asistencia/controlAsistencia.vue"),
  props: true,
},

  {
    path: "/logout",
    name: "logout",
    component: () => import("../views/Logout.vue"),
  },
];

const router = new VueRouter({
  //mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
    //valida si la route existe
    if (to.matched.length > 0) {//si existe

      //verifica si la route requiere login
      if(to.matched.some(record => record.meta.requiresAuth)) {//si requiere login
        if (store.getters.isLoggedIn) {
          next()
          return
        } else {
          router.push({
            name: 'Unauthorized'
          });
        }
      } else {//sino requiere login
        next()
      }
    } else {//sino existe
      router.push({
        name: 'NotFound'
      });
    }
  })

export default router;
